export default [
  {
    title   : 'Dashboard',
    route   : 'home',
    icon    : 'HomeIcon',
    action  : 'read',
    resource: 'Admin'
  },
  {
    header  : 'Communicatie',
    action  : 'read',
    resource: 'Admin'
  },
  {
    title   : 'Kalender',
    route   : 'calendar',
    icon    : 'CalendarIcon',
    action  : 'read',
    resource: 'Admin'
  },
  {
    header  : 'Administratie',
    action  : 'read',
    resource: 'Admin'
  },
  {
    title   : 'Gebruikers',
    route   : 'user-list',
    icon    : 'UserCheckIcon',
    action  : 'read',
    resource: 'Admin'
  },
  {
    title   : 'Groepen',
    icon    : 'GridIcon',
    route   : 'groups',
    action  : 'read',
    resource: 'Admin',
  },
  {
    title   : 'Contactpersonen',
    route   : 'contact-list',
    icon    : 'UsersIcon',
    action  : 'read',
    resource: 'Admin'
  },
  {
    header  : 'Beheer',
    action  : 'read',
    resource: 'Admin'
  },
  {
    title   :  'Instellingen',
    route   : 'organisation-settings',
    icon    : 'SettingsIcon',
    action  : 'read',
    resource: 'Admin'
  },
  {
    title   : 'Integraties',
    route   : 'integrations',
    tag     : 'beta',
    icon    : 'RefreshCcwIcon',
    action  : 'read',
    resource: 'Admin'
  },
  {
    title   : 'Importeren',
    tag     : 'beta',
    icon    : 'UploadIcon',
    action  : 'read',
    resource: 'Admin',
    children: [
      {
        title   : 'Gebruikers',
        route   : 'import',
        icon    : 'UserIcon',
        action  : 'read',
        resource: 'Admin'
      },
      {
        title   : 'Groepen',
        route   : 'importgroups',
        icon    : 'UsersIcon',
        action  : 'read',
        resource: 'Admin'
      },
      {
        title   : "Profielfoto's",
        route   : 'importProfilePictures',
        icon    : 'ImageIcon',
        action  : 'read',
        resource: 'Admin'
      },
    ]
  },
]
