<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <h2 class="content-header-title float-left pl-1 mb-0">
        {{ $store.state.pageTitle }}
      </h2>
      <div class="breadcrumb-wrapper">
        <b-breadcrumb>
          <b-breadcrumb-item to="/">
            <feather-icon icon="HomeIcon" size="16" class="align-text-top" />
          </b-breadcrumb-item>
          <b-breadcrumb-item
            v-for="item in $route.meta.breadcrumb"
            :key="item.text"
            :active="item.active"
            :to="item.to"
          >
            {{ item.text }}
          </b-breadcrumb-item>
        </b-breadcrumb>
      </div>
      <!-- <dark-Toggler class="d-none d-lg-block" /> -->
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
        v-if="activeUserInfo"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ activeUserInfo.displayName }}
            </p>
            <span class="user-status">{{
              organisation ? organisation.shortName || "" : ""
            }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="
              activeUserInfo.profilePic ? activeUserInfo.profilePic.small : null
            "
            :text="initials ? initials : ''"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          @click="navigate('account-settings')"
          link-class="d-flex align-items-center"
        >
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>Account</span>
        </b-dropdown-item>

        <b-dropdown-divider />

         <b-dropdown-item
         :key="i"
          v-for="(o, i) in activeUserInfo.organisations"
          @click="selectOrganisation(i)"
          link-class="d-flex align-items-center"
        >
          <feather-icon size="16" icon="FlagIcon" class="mr-50" :class="$store.state.organisation && i == $store.state.organisation.id ? 'text-success' : ''" />
          <span :class="$store.state.organisation && i == $store.state.organisation.id ? 'text-success' : ''">{{o.shortName}}</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item @click="logout" link-class="d-flex align-items-center">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Afmelden</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BBreadcrumb,
  BBreadcrumbItem,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BBreadcrumb,
    BBreadcrumbItem,
    // Navbar Components
    DarkToggler,
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    initials() {
      if (this.$store.state.AppActiveUser && this.$store.state.AppActiveUser != {}) {
        let name = this.$store.state.AppActiveUser.displayName;
        let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");

        let initials = [...name.matchAll(rgx)] || [];

        initials = (
          (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
        ).toUpperCase();

        return initials;
      } else {
        return "";
      }
    },
    organisation() {
      return this.$store.state.organisation;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/signOut");
    },
    navigate(name) {
      this.$router.push({ name });
    },
    selectOrganisation(id){
      this.$store.dispatch('selectOrganisation', id)
    }
  },

  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
};
</script>
